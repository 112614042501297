<template>
  <p v-if="needActivation" class="w-100 text-center bg-red d-block m-0 py-2">
    <router-link class="text-light" :to="{name: 'client.activate'}">يرجى تفعيل الحساب</router-link>
  </p>
  <nav class="navbar navbar-expand-xl navbar-dark navbar-joauctions p-xl-0 shadow">
    <div class="container-fluid bg-black">
      <router-link class="navbar-brand" to="/">
        <img class="logo" src="@/assets/images/logo-jomazad.png" alt="جو مزاد">
      </router-link>
      <button type="button" data-toggle="collapse" data-target="#joAuctionNavbar" aria-controls="joAuctionNavbar"
              aria-expanded="false" aria-label="اظهار القائمة" class="navbar-toggler">
        <i class="fas fa-bars text-light-danger"></i>
      </button>
      <div id="joAuctionNavbar" class="collapse navbar-collapse">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'index'}">الرئيسية</router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'guides'}">آلية الشراء</router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'sell.cars'}">آلية البيع</router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'questions'}">
              الأسئلة الشائعة
            </router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'about'}">من نحن</router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'contact'}">اتصل بنا</router-link>
          </li>
          <li class="nav-item d-block d-lg-none">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'auctions.index'}">جميع المزادات</router-link>
          </li>
        </ul>
        <ul class="navbar-nav" v-if="!$store.getters.authorized">
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'register'}">إشتراك جديد</router-link>
          </li>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: 'login'}">تسجيل الدخول</router-link>
          </li>
        </ul>
        <ul class="navbar-nav" v-else>
          <li class="nav-item">
            <router-link exact-active-class="active" class="nav-link" :to="{name: dashboard}">لوحة التحكم</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
              {{ $store.getters.firstName }}
            </a>
            <div class="dropdown-menu animated fadeIn shadow rounded-0">
              <router-link class="dropdown-item" to="/">الرئيسية</router-link>
              <router-link class="dropdown-item" :to="{name: dashboard}">لوحة التحكم</router-link>
              <router-link class="dropdown-item" :to="{name: 'change.password'}">كلمة المرور</router-link>
              <div class="dropdown-divider"></div>
              <modal-btn btn-class="btn dropdown-item" target="#confirm_sign_out">تسجيل الخروج</modal-btn>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";

export default {
  components: {ModalBtn},
  computed: {
    dashboard() {
      return this.$store.getters.dashboard;
    },
    needActivation() {
      return this.$store.getters.needActivation;
    }
  },
}
</script>

<style scoped>
.navbar-joauctions {
  padding: 0;
  margin: 0;
  background-color: #000;
}

.navbar-joauctions .nav-item {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.navbar-joauctions .nav-item .nav-link {
  color: #ff0000;
  font-weight: 500;
  padding: 25px 15px;
  font-size: 15px;
  border-bottom: 3px solid #000;
}

.navbar-joauctions .nav-item .nav-link:hover {
  border-color: #f44336
}

.navbar-joauctions .nav-item .active {
  border-color: #f44336
}

.dropdown-menu {
  left: 0 !important;
  right: auto !important;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #ff0000 !important;
}

@media (max-width: 768px) {
  .navbar-joauctions .navbar-brand img {
    width: 200px
  }

  #joAuctionNavbar .navbar-nav {
    margin-right: unset !important;
  }
}

.logo {
  max-width: 100%;
}


@media (max-width: 400px) {
  .navbar-joauctions .navbar-brand img {
    width: 160px;
    padding: 0;
    margin: 0
  }

  .navbar-light .navbar-toggler {
    font-size: 1rem
  }
}

@media (max-width: 1380px) {
  .navbar-joauctions .nav-item .nav-link {
    font-size: 14px;
    padding: 20px 15px
  }
}

@media (max-width: 1200px) {
  #joAuctionNavbar .navbar-nav {
    margin-right: unset !important;
    padding: 0;
  }

  .container-fluid {
    max-width: 95%;
  }
}
</style>