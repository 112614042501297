<template>
  <teleport to="#app">
    <confirm-model
      :target-id="targetId"
      :title="title"
      confirm-text="حذف"
      @confirmed="destroyItem">
      <slot>هل أنت متأكد من حذف هذا العنصر ؟</slot>
    </confirm-model>
  </teleport>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import messages from "../../common/messages";
import Storage from "@/common/Storage";

export default {
  emits: ['deleted'],
  props: {
    targetId: {required: true},
    url: {required: true},
    rowId: {required: true, default: 'row'},
    title: {default: 'حذف عنصر'},
    description: {},
    storagesClear: {type: Array},
    removeRaw: {type: Boolean, default: true}
  },
  components: {ConfirmModel},
  methods: {
    async destroyItem() {
      if (!this.url)
        return console.error('no delete link');

      const response = await http.send(this.url, {_method: 'DELETE'});
      if (http.responseAccepted(response)) {
        if (response.data.msg)
          return http.popupMessage('error', response.data.msg);

        if (!response.data.id)
          console.error('no response id');

        if (this.removeRaw)
          await this.hideItemById(this.rowId);

        http.popupMessage('success', messages.deleted);
        this.hideModal(`#${this.targetId}`);
        this.$emit('deleted', response.data.id);

        if (!!this.storagesClear && this.storagesClear.length > 0)
          for (const storage of this.storagesClear)
            Storage.removeStartWith(storage);
      }
    }
  }
}
</script>
