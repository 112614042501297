<template>
<div class="container">
    <h1 class="text-red text-center">إتصل بنا</h1>
  <div class="row">
    <div class="col-md-8">
      <form @submit.prevent="submit">
        <div class="row mb-4">
          <div class="col-md-6">
            <label for="first_name">الاسم الاول</label>
            <input type="text" class="form-control" placeholder="الاسم الاول" id="first_name" v-model="first_name"/>
          </div>
          <div class="col-md-6">
            <label for="last_name">الاسم الاخير</label>
            <input type="text" class="form-control" placeholder="الاسم الاخير" id="last_name" v-model="last_name"/>
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="subject">الموضوع</label>
            <select class="form-control" name="subject" id="subject" v-model="subject">
              <option value="" hidden>إختر</option>
              <option value="شراء المركبات">شراء المركبات</option>
              <option value="بيع المركبات">بيع المركبات</option>
              <option value="التبليغ عن مشكلة">التبليغ عن مشكلة</option>
              <option value="مشاكل اخرى">اخرى</option>
            </select>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <label for="email">البريد الإلكتروني</label>
            <input type="email" class="form-control" placeholder="البريد الإلكتروني" id="email" v-model="email"/>
          </div>
          <div class="col-md-6">
            <label for="phone">رقم الهاتف</label>
            <input type="text" class="form-control" placeholder="رقم الهاتف" id="phone" v-model="phone"/>
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="message">الرسالة</label>
            <textarea id="message" v-model="message" name="message" placeholder="الرسالة..." rows="5"
                      class="form-control"></textarea>
          </div>
        </div>

         <div class="row mb-4">
          <div class="col-md-12">
            <button class="btn bg-e0 btn-lg rounded-0">إرسال</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4">
     <div class="row mb-4">
       <div class="col-md-12">
          <iframe
              class="w-100"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1692.5269024440342!2d35.845062!3d31.959437000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b5fb85d7981af%3A0x5c4e8b1b89fbe0c3!2sXR5W%2BQ2%2C%20Amman%2C%20Jordan!5e0!3m2!1sen!2sus!4v1687002998995!5m2!1sen!2sus"
              height="450" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>
     </div>
      <div class="row">
        <div class="col-md-12">
          <ul class="pm-0" style="font-size: 18px">
           <li>مجمع البركة 2 رقم البناية 334, الطابق السابع, شارع حسين الخواجة, عمان, الأردن</li>
            <li>
              <a href="tel:0797743555" class="link danger">0797743555</a>
            </li>
         </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      subject: '',
      email: '',
      phone: '',
      message: '',
    }
  },
  methods: {
    async submit() {
      if (!this.first_name) {
        http.errorMessage('يرجى تعبئة الاسم الاول')
        return;
      }
      if (!this.last_name) {
        http.errorMessage('يرجى تعبئة الاسم الأخير')
        return;
      }

      if (!this.subject) {
        http.errorMessage('يرجى تعبئة الموضوع')
        return;
      }

      if (!this.email) {
        http.errorMessage('يرجى تعبئة البريد الالكتروني')
        return;
      }

      if (!this.phone) {
        http.errorMessage('يرجى تعبئة رقم الهاتف')
        return;
      }

      if (!this.message) {
        http.errorMessage('يرجى تعبئة الرسالة')
        return;
      }

      const response = await http.send("feedback", {
        name: `${this.first_name} ${this.last_name}`,
        subject: this.subject,
        email: this.email,
        msg: this.message,
      });
      if (http.responseAccepted(response)) {
        http.successMessage('شكرا لك سيتم التواصل معك في أقرب وقت')
        this.initialData();
      }
    },
    initialData() {
      this.first_name = '';
      this.last_name = '';
      this.subject = '';
      this.email = '';
      this.phone = '';
      this.message = '';
    }
  }
}
</script>

<style scoped></style>