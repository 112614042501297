<template>
  <section
    class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-1a17a1b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="1a17a1b"
    data-element_type="section"
    data-settings='{"background_background":"classic"}'
  >
    <div class="elementor-container elementor-column-gap-default">
      <Carousel
        class="w-100 d-none d-lg-block"
        v-if="!!auctions && auctions.length > 0"
        v-bind="settings"
      >
        <Slide v-for="auction in auctions" :key="auction.id" class="p-2">
          <div class="carousel__item">
            <div class="card w-100 h-100">
              <router-link
                :to="{
                  name: 'auctions.bid',
                  params: { auction: auction.slug },
                }"
              >
                <img
                  :src="
                    auction.image
                      ? `${$store.getters.publicPath}/storage/${auction.image}`
                      : ''
                  "
                  class="card-img-top"
                  alt=""
                />
              </router-link>
              <div class="card-body">
                <h5 class="card-title">
                  {{ auction.name }}
                </h5>
                <div class="data-container w-100">
                  <div class="data">
                    <p class="pm-0 text-left">
                      <span class="text-secondary">الصنع: </span>
                      <span>{{ auction.year }}</span>
                    </p>
                    <p class="pm-0 text-left">
                      <span class="text-secondary">السعر: </span>
                      <span>{{ auction.current_price }} دينار</span>
                    </p>
                    <p class="pm-0 text-left">
                      <span
                        class="text-left ml-1"
                        dir="ltr"
                        v-html="auction.formatted_finish_date"
                      ></span>
                      <span class="text-secondary">:ينتهي </span>
                    </p>
                  </div>
                  <div
                    class="data timer-container  d-flex justify-content-start"
                  >
                    <section>
                      <label class="text-secondary mb-0 w-100 text-left"
                        >:الوقت</label
                      >
                      <Timer :timer-seconds="auction.seconds" />
                    </section>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-transparent">
                <router-link
                  class="btn btn-danger btn-block rounded-0"
                  :to="{
                    name: 'auctions.bid',
                    params: { auction: auction.slug },
                  }"
                >
                  المزاودة
                </router-link>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <div
        class="d-block d-lg-none has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f95b54d"
        data-id="f95b54d"
        data-element_type="column"
      >
        <div class="elementor-widget-wrap elementor-element-populated">
          <div
            class="elementor-element elementor-element-fe1240e elementor-widget elementor-widget-heading"
            data-id="fe1240e"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div class="elementor-widget-container">
              <h2
                class="elementor-heading-title elementor-size-default"
                style="font-size:30px;"
              >
                أحدث المزادات
              </h2>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row" v-if="!!auctions && auctions.length > 0">
              <div
                class="col-md-4 mb-2"
                v-for="auction in auctions"
                :key="auction.id"
              >
                <div class="card w-100 h-100">
                  <router-link
                    :to="{
                      name: 'auctions.bid',
                      params: { auction: auction.slug },
                    }"
                  >
                    <img
                      :src="
                        auction.image
                          ? `${$store.getters.publicPath}/storage/${auction.image}`
                          : ''
                      "
                      class="card-img-top"
                      alt=""
                    />
                  </router-link>
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ auction.name }}
                    </h5>
                    <div class="data-container w-100">
                      <div class="data">
                        <p class="pm-0">
                          <span class="text-secondary">الصنع: </span>
                          <span>{{ auction.year }}</span>
                        </p>
                        <p class="pm-0">
                          <span class="text-secondary">السعر: </span>
                          <span>{{ auction.current_price }} دينار</span>
                        </p>
                        <p class="pm-0">
                          <span class="text-secondary">ينتهي: </span>
                          <span
                            dir="ltr"
                            v-html="auction.formatted_finish_date"
                          ></span>
                        </p>
                      </div>
                      <div
                        class="data timer-container d-flex justify-content-end"
                      >
                        <section>
                          <label class="text-secondary mb-0">الوقت: </label>
                          <Timer :timer-seconds="auction.seconds" />
                        </section>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer bg-transparent">
                    <router-link
                      class="btn btn-danger btn-block rounded-0"
                      :to="{
                        name: 'auctions.bid',
                        params: { auction: auction.slug },
                      }"
                    >
                      المزاودة
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class=" jumbotron bg-e0 text-center w-100">
                  <h4 class="text-center">لا يوجد مزادات</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="elementor-element elementor-element-2fb717a elementor-align-center elementor-widget elementor-widget-button"
      data-id="2fb717a"
      data-element_type="widget"
      data-widget_type="button.default"
    >
      <div class="elementor-widget-container">
        <div class="elementor-button-wrapper">
          <router-link
            :to="{ name: 'auctions.index' }"
            class="elementor-button-link elementor-button elementor-size-sm"
          >
            <span class="elementor-button-content-wrapper">
              <span class="elementor-button-text">
                عرض جميع المزادات
              </span>
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Timer from "@/components/UI/Timer.vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "IndexAuctionSection",
  components: {
    Timer,
    Carousel,
    Slide,
    // Pagination,
    Navigation,
  },
  props: {
    auctions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    settings() {
      return {
        itemsToShow: this.auctions.length > 3 ? 3.5 : 2.5,
        snapAlign: "center",
        autoplay: 5000,
        pauseAutoplayOnHover: true,
      };
    },
  },
};
</script>

<style scoped>
.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>
