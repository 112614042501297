<template>
  <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
          <form @submit.prevent="addCar" autocomplete="off" id="add_car_form">
          <base-card footer="bg-e0">
            <template #header>بيع سيارتك</template>
            <template #body>
              <div class="row">
                <div class="col-md-6">
                  <form-input
                      :autocomplete="false"
                      id="full_name"
                      text="الاسم"
                  ></form-input>
                </div>
                <div class="col-md-6">
                  <form-input
                      :autocomplete="false"
                      id="mobile"
                      text="رقم الهاتف"
                  />
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <form-input id="address" text="العنوان"></form-input>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <label>تاريخ الميلاد</label>-->
<!--                  <div class="row pm-0">-->
<!--                    <bootstrap-select-->
<!--                        class="col-sm-4"-->
<!--                        title="اليوم"-->
<!--                        :options="31"-->
<!--                        id="birth_day"-->
<!--                    ></bootstrap-select>-->
<!--                    <bootstrap-select-->
<!--                        class="col-sm-4"-->
<!--                        title="الشهر"-->
<!--                        :options="12"-->
<!--                        id="birth_month"-->
<!--                    ></bootstrap-select>-->
<!--                    <bootstrap-select-->
<!--                        class="col-sm-4"-->
<!--                        title="السنة"-->
<!--                        :options="years"-->
<!--                        id="birth_year"-->
<!--                    ></bootstrap-select>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <hr/>
              <div class="row">
                <div class="col-md-6">
                  <label for="manufacturer">نوع المركبة</label>
                   <BootstrapSelect
                       id="manufacturer"
                       :options="manufacturers"
                       title="نوع المركبة"
                       :empty-option="false"
                       @changed="setModelsList"
                   />
                </div>
                <div class="col-md-6">
                  <label for="manufacturer">موديل المركبة</label>
                   <BootstrapSelect
                       id="model"
                       :options="models"
                       title="موديل المركبة"
                       :empty-option="false"
                       :empty-disabled="true"
                   />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                   <FormImage
                       id="images"
                       ref="images"
                       :shadow="false"
                       name="images[]"
                       accept="image/*"
                       :multiple="true"
                   >صور المركبة</FormImage>
                </div>
                <div class="col-md-6">
                  <label for="year_of_make">سنة الصنع</label>
                  <BootstrapSelect
                      id="year_of_make"
                      title="سنة الصنع"
                      :options="auctionYears"
                      :empty-option="false"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <form-input
                      :autocomplete="false"
                      id="sell_price"
                      type="number"
                      text="سعر البيع المتوقع"
                  />
                </div>
              </div>
            </template>
            <template #footer>
              <button type="submit" class="btn btn-dark btn-block">
                إضافة
              </button>
            </template>
          </base-card>
        </form>
        </div>
    </div>
  </div>
</template>

<script>
import BootstrapSelect from "@/components/Forms/BootstrapSelect.vue";
import FormInput from "@/components/Forms/FormInput.vue";
import BaseCard from "@/components/UI/BaseCard.vue";
import random from "@/common/random";
import FormImage from "@/components/Forms/FormImage.vue";
import cars from "@/mixins/cars";

export default {
  components: {FormImage, BaseCard, FormInput, BootstrapSelect},
  mixins: [cars],
  data() {
    return {
      manufacturers: [],
      models: [],
    }
  },
  computed: {
    years() {
      return random.list(new Date().getFullYear() - 17, {diff: 80});
    },
    auctionYears() {
      return random.list(new Date().getFullYear(), {future: 2})
    },
  },
  methods: {
    async addCar(e) {
      const formData = new FormData(e.target)
      const response = await http.send('selling-cars', formData, {
        headers: {'Content-type': 'multipart/form-data'}
      });
      if (http.responseAccepted(response)) {
        http.errorMessage('شكرا لك سيتم التواصل معك في أقرب وقت لمتابعة الإجراءات!')
        await this.$router.replace({name: 'sell.cars'})
      }
    }
  },
  async created() {
    await this.setManufacturerList()
  }
}
</script>

<style scoped>

</style>