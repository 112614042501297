<template>
  <section class="pm-0">
    <img
        src="@/assets/images/bg.jpg"
        alt="جو مزاد"
        class="index-image"
    />
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <AuctionsList type="index" :user="authorizedId"/>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <PartnersData/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AuctionsList from "@/components/Auctions/AuctionsList";
import PartnersData from "@/components/Users/PartnersData";

export default {
  components: {AuctionsList, PartnersData},
  computed: {
    authorizedId() {
      const authorized = this.$store.getters.authorized;
      return !!authorized ? authorized.id : null;
    },
  }
};
</script>

<style scoped>
.index-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
</style>
