const messages = {
  joauctions: 'جو مزاد',
  // success messages
  updated: 'تم التعديل بنجاح',
  saved: 'تم الحفظ بنجاح',
  deleted: 'تم الحذف بنجاح',
  passwordChanged: 'تم تغيير كلمة المرور بنجاح',
  imageSent: 'تم تسليم الصورة بنجاح',
  // error messages
  shouldSelectImg: 'يرجى تحديد صورة',
  imageUploaded: 'تم تسليم هذه الصورة مسبقاً',
  linkError: 'الرابط غير صالح',
  // INFO MESSAGES
  validMobilePattern: 'يكون الرقم صالحاً إذا كان باحدى صيغ خطوط الشركات الأردنية',
  auctionsLimit: 'عدد المزادات القصوى المسموحة للمستخدم للمزاودة وقد ترتب على المستخدم مزادات غير مدفوعة',
  checkFields: 'يرجى التأكد من البيانات',
  created_at: 'تاريخ الإنشاء',
  failedToSave: 'فشل الحفظ',
  somethingWentWrong: 'حدث خطأ ما!',
  image: 'صورة',
  images: 'صور',
  // Auctions
  auctions: {
    all: 'جميع المزادات',
    current: 'المزادات الحالية',
    stumbled: 'المزادات المتعثرة',
    unpaid: 'المزادات غير المدفوعة',
    received: 'المزادات المستلمة',
    deleted: 'المزادات المحذوفة',
    'need-invoice': 'مزادات اصدار الفواتير',
    'need-invoice-checking': 'مزادات تدقيق الفواتير',
    'sold-externally': 'المزادات المسحوبة',
    'not-received': 'المزادات غير مستلمة',
    'unpaid-floor': 'مزادات غير مدفوعة الارضيات',
  }
};
export default messages;
