<template>
  <div class="container">
    <div class="row mb-5 image-main">
      <div class="col-12">
        <h1 class="text-center text-red">شراء السيارات بسهولة عبر الإنترنت!</h1>

        <div class="w-100 d-flex-center image-wrapper">
          <img decoding="async"
               src="@/assets/jomazad/images/pngegg.png"
               class="w-100" alt=""
               data-src="@/assets/jomazad/images/pngegg.png"
               data-sizes="auto" sizes="960px"
               srcset="@/assets/jomazad/images/pngegg.png 960w, @/assets/jomazad/images/pngegg-300x188.png 300w, @/assets/jomazad/images/pngegg-768x480.png 768w, @/assets/jomazad/images/pngegg-600x375.png 600w">
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12" style="font-size: 16px">
        <p class="mb-2 text-justify mb-3">مهمتنا الرئيسية هي حمايتك من البائعين المخادعين وتقليل مخاطر شراء السيارة غير الناجح. ستجد هنا ليس فقط السيارات المستعملة في حالة ممتازة ، ولكن أيضًا السيارات مع تقرير فحص المركبة. ومن ثم ضمان راحة البال. يتم فحص سيارات المزاد من قبل خبراء مستقلين ، ويقدمون معلومات حول كل سيارة مستعملة مثل:</p>
        <div class="mb-1 space-x-2 d-flex justify-content-start align-items-center">
          <i class="fas fa-sort-numeric-up fa-1-5x text-danger"></i>
          <span>الكيلومترات المقطوعة</span>
        </div>
        <div class="mb-1 space-x-2 d-flex justify-content-start align-items-center">
          <i class="fas fa-dot-circle fa-1-5x text-danger"></i>
          <span>فحص الشاصيه</span>
        </div>
        <div class="mb-1 space-x-2 d-flex justify-content-start align-items-center">
          <i class="fas fa-magic fa-1-5x text-danger"></i>
          <span>الحالة العامة للمركبة</span>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12" style="font-size: 16px">
        <p class="mb-2">يحق للمشترين الاختيار من بين مجموعة واسعة من السيارات من Audi و BMW و Renault و Toyota و Volkswagen و Mercedes–Benz و Opel و Ford و Peugeot و Lexus و Citroen وغيرها من الشركات المصنعة. يتم عرض السيارات على الموقع الإلكتروني للمشتركين بأسعار تنافسية. عملية المزاودة آمنة و شفافة و سهلة للغاية.</p>
        <div class="w-100 d-flex justify-content-center">
          <img decoding="async" style="max-height: 106px"
               src="@/assets/jomazad/images/SELL.png"
               class="w-100" alt=""
               data-src="@/assets/jomazad/images/SELL.png"
               data-sizes="auto" sizes="624px"
               srcset="@/assets/jomazad/images/SELL.png 624w, @/assets/jomazad/images/SELL-300x51.png 300w, @/assets/jomazad/images/SELL-600x102.png 600w">
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12" style="font-size: 16px">
        <div class="text-red font-weight-bold">
          <div class="w-100 d-flex justify-content-between flex-wrap">
            <p>كما تقدم جو مزاد خدمات ما بعد البيع من تحضير المستندات المطلوبة و خدمات المساعدة في نقل الملكية بدائرة الترخيص.</p>
            <router-link :to="{name: 'contact'}" target="_blank" class="btn btn-accent rounded-0 min-w-100px my-3 my-lg-0">
              إتصل بنا
            </router-link>
          </div>
          <p>سنكون سعداء للإجابة على جميع أسئلتك!</p>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12" style="font-size: 16px">
        <h3 class="text-red font-weight-bold">كيف تصبح مزاود؟</h3>
        <ol class="pl-3">
          <li>أنقر على إشتراك جديد.</li>
          <li>أدخل اسمك وبريدك الإلكتروني ورقم هاتفك وأنشئ كلمة مرور قوية.</li>
          <li>أنقر على سجل الآن.</li>
          <li>تسجيل الدخول باسم المستخدم وكلمة المرور.</li>
        </ol>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12" style="font-size: 16px">
        <h3 class="text-red font-weight-bold">التسجيل المادي:</h3>
        <p>
          سيحتاج العميل إلى ملء “استمارة التسجيل” وتقديم نسخة من بطاقة الهوية أو جواز السفر في مكتب الاستقبال. انقر فوق اتصل بنا للحصول على خريطة موقعنا.
        </p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12" style="font-size: 16px">
        <h3 class="text-red font-weight-bold">التفعيل:</h3>
        <p>
          سيتلقى فريق خدمة العملاء لدينا إيداعك والتحقق من المستندات و تفعيل حسابك. بمجرد التفعيل، ستتلقى رسالة تأكيد بالبريد الإلكتروني المسجل الخاص بك إلى جانب اسم المستخدم وكلمة المرور الخاصين بك.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12" style="font-size: 16px">
        <h3 class="text-red font-weight-bold">بمجرد تسجيل الدخول ، يمكنك:</h3>
        <ol class="pl-3">
          <li>عرض جميع المزادات.</li>
          <li>المزايدة على مزاداتنا الحالية عبر الإنترنت.</li>
          <li>رفع سقف الإيداع لزيادة حد الشراء الخاص بك.</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <router-link :to="{name: 'register'}" target="_blank" class="btn btn-accent rounded-0">
          إشتراك
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.image-main .image-wrapper:hover img {
  filter: brightness(120%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}
</style>