<template>
  <div class="container">
    <div class="row justify-content-center">
      <div :class="classes">
        <form @submit.prevent="createUser" id="register" autocomplete="off">
          <base-card :header="header" footer="bg-e0">
            <template #header>إنشاء حساب جديد</template>
            <template #body>
              <p class="text-danger text-left font-weight-bolder">
                المعلومات الشخصية
              </p>
              <div class="row">
                <div class="col-md-6">
                  <form-input
                    :autocomplete="false"
                    id="email"
                    text="البريد الإلكتروني"
                  ></form-input>
                </div>
                <div class="col-md-6">
                  <form-input
                    :autocomplete="false"
                    id="username"
                    text="إسم المستخدم"
                    abbr="يستخدم إسم المستخدم للدخول الى حساب المستخدم لاحقاً"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <form-input id="name" text="الإسم الرباعي"></form-input>
                </div>
                <div class="col-md-6">
                  <label>تاريخ الميلاد</label>
                  <div class="row pm-0">
                    <bootstrap-select
                      class="col-sm-4"
                      title="اليوم"
                      :options="31"
                      id="day"
                    ></bootstrap-select>
                    <bootstrap-select
                      class="col-sm-4"
                      title="الشهر"
                      :options="12"
                      id="month"
                    ></bootstrap-select>
                    <bootstrap-select
                      class="col-sm-4"
                      title="السنة"
                      :options="years"
                      id="year"
                    ></bootstrap-select>
                  </div>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <label>الجنسية</label>-->
<!--                  <bootstrap-select-->
<!--                    title="الجنسية"-->
<!--                    id="nationality"-->
<!--                    @changed="setNationality"-->
<!--                    :options="nationalities"-->
<!--                    :selected="nationality"-->
<!--                  ></bootstrap-select>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <label>{{ nationalNumDesc }}</label>-->
<!--                  <form-input-->
<!--                    id="national_number"-->
<!--                    :type="nationalityType"-->
<!--                    :placeholder="nationalNumDesc"-->
<!--                  ></form-input>-->
<!--                </div>-->
<!--              </div>-->
              <div class="row">
                <div class="col-md-6">
                  <form-input
                    :autocomplete="false"
                    type="password"
                    id="password"
                    text="كلمة المرور"
                  ></form-input>
                </div>
                <div class="col-md-6">
                  <form-input
                    :autocomplete="false"
                    type="password"
                    id="password_confirmation"
                    text="تأكيد كلمة المرور"
                  ></form-input>
                </div>
              </div>
              <hr />
              <p class="text-danger text-left font-weight-bolder">
                <abbr :title="validMobilePattern">ارقام الهواتف</abbr>
              </p>
              <mobile-inputs></mobile-inputs>
              <hr />
<!--              <p class="text-danger text-left font-weight-bolder">-->
<!--                معلومات السكن-->
<!--              </p>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <label>المحافظة</label>-->
<!--                  <bootstrap-select-->
<!--                    title="المحافظة"-->
<!--                    id="city_id"-->
<!--                    :options="cities"-->
<!--                    @changed="setAreas"-->
<!--                  ></bootstrap-select>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <label>المنطقة</label>-->
<!--                  <bootstrap-select-->
<!--                    title="المنطقة"-->
<!--                    id="area_id"-->
<!--                    :options="areas"-->
<!--                  ></bootstrap-select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <form-input id="street" text="الشارع"></form-input>-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <form-input id="building" text="رقم البناية"></form-input>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6">-->
<!--                  <form-image-->
<!--                    :shadow="false"-->
<!--                    :button="upload"-->
<!--                    id="identifyImage"-->
<!--                    @uploaded="setImage"-->
<!--                    @cleared="clearFilePath"-->
<!--                    >صورة الهوية</form-image-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="col-md-6">-->
<!--                  <label>عرض الهوية</label>-->
<!--                  <modal-btn-->
<!--                    btn-class="btn nr-block"-->
<!--                    target="#show-id-image"-->
<!--                    :class="show"-->
<!--                    :disabled="!filePath"-->
<!--                    >عرض</modal-btn-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--              <div v-if="isAdmin">-->
<!--                <hr />-->
<!--                <p class="text-danger text-left font-weight-bolder">-->
<!--                  معلومات الملف-->
<!--                </p>-->
<!--                <div class="row">-->
<!--                  <div class="col-md-6">-->
<!--                    <form-input id="file_no" text="رقم الملف"></form-input>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </template>
            <template #footer>
              <button type="submit" class="btn" :class="submit.class">
                {{ submit.text }}
              </button>
              <p class="text-left mb-0 mt-2" v-if="!adminPage">
                <span>لديك حساب بالفعل؟</span>&nbsp;
                <router-link to="/login" class="link danger"
                  >تسجيل الدخول</router-link
                >
              </p>
            </template>
          </base-card>
        </form>
      </div>
    </div>
  </div>
  <teleport to="#app">
    <confirm-model
      target-id="show-id-image"
      title="صورة الهوية"
      header-bg="bg-info"
      confirm-btn="d-none"
      cancel-btn="btn-info"
      cancel-text="تم"
    >
      <img class="w-100" :src="filePath" alt="" />
    </confirm-model>
  </teleport>
</template>
<script>
import { mapGetters } from "vuex";
import user_mixin from "@/mixins/user_mixin";
import files_mixin from "@/mixins/files_mixin";
import random from "@/common/random";
import BaseCard from "../../UI/BaseCard";
import FormInput from "../../Forms/FormInput";
import BootstrapSelect from "../../Forms/BootstrapSelect";
import ConfirmModel from "@/components/UI/ConfirmModel";
import ModalBtn from "@/components/UI/ModalBtn";
import MobileInputs from "@/components/Users/Mobile/MobileInputs";
import validation from "@/validation/validation";
import FormImage from "@/components/Forms/FormImage";

export default {
  mixins: [user_mixin, files_mixin],
  components: {
    MobileInputs,
    ConfirmModel,
    BootstrapSelect,
    FormInput,
    BaseCard,
  },
  computed: {
    ...mapGetters(["nationalities", "cities", "areas", "isAdmin"]),
    validMobilePattern() {
      return messages.validMobilePattern;
    },
    years() {
      return random.list(new Date().getFullYear() - 17, { diff: 80 });
    },
    adminPage() {
      return this.$route.name === "users.create";
    },
    submit() {
      return {
        text: this.adminPage ? "إنشاء" : "سجل الان",
        class: this.adminPage ? "btn-info nr-shadow" : "btn-dark nr-block",
      };
    },
    upload() {
      return this.adminPage ? "btn-soft-danger" : "btn-red";
    },
    show() {
      let classes = this.adminPage ? "btn-info" : "btn-dark";
      if (this.filePath) classes += " animated pulse";
      return classes;
    },
    header() {
      return "text-light " + (this.adminPage ? "bg-soft-danger" : "bg-red");
    },
    classes() {
      return this.adminPage ? "pm-0 col-sm-12" : "col-sm-8";
    },
    required() {
      const rules = [
        "username",
        "name",
        "day",
        "month",
        "year",
        // "nationality",
        // "national_number",
      ];
      if (this.$store.getters.isClient) {
        rules.push(
          "email",
          "password",
          "password_confirmation",
          // "area_id",
          // "street",
          // "building"
        );
      }

      return rules;
    },
  },
  methods: {
    async createUser(e) {
      const valid = validation.validate("register", this.required);

      if (!(!valid || !valid.data || !valid.status)) {
        const formData = new FormData(e.target);

        if (
          !!$_("#mobile1").val() &&
          $_("#mobile1").val() === $_("#mobile2").val()
        ) {
          http.popupMessage("error", "رقم الهاتف مكرر");
        } else {
          await this.$store.dispatch("register", formData);
        }
      } else {
        http.popupMessage("error", messages.checkFields);
      }
    },
    showIdImage() {
      this.clickById("identifyImage");
    },
  },
};
</script>
<style scoped>
.row {
  padding: 0.25rem 0;
}
</style>
