<template>
<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="text-center text-red">عنّا</h1>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12">
      <p class="font-weight-bold text-justify" style="font-size: 17px">
      Jomazad.com هو عبارة عن منصة على الإنترنت لمزادات السيارات من جميع أنحاء الأردن. تباع السيارات المستعملة في مزادات السيارات. Jomazad.com هو جزء من Joauctions.com الذي تأسس في عام 2011 لتوفير منصة رقمية رائدة لبيع وشراء السيارات المستعملة عبر الإنترنت ، ومقرها في عمان ، الأردن.
    </p>
    </div>
  </div>
  <div class="row align-items-center mb-5" dir="ltr">
    <div class="col-md-6">
      <img decoding="async"
           src="@/assets/jomazad/images/bmw-1024x476.png"
           class="w-100" alt=""
           srcset="@/assets/jomazad/images/bmw-1024x476.png 1024w, @/assets/jomazad/images/bmw-300x139.png 300w, @/assets/jomazad/images/bmw-768x357.png 768w, @/assets/jomazad/images/bmw-1536x714.png 1536w, @/assets/jomazad/images/bmw-600x279.png 600w, @/assets/jomazad/images/bmw.png 1920w"
           sizes="(max-width: 1024px) 100vw, 1024px">
    </div>
    <div class="col-md-6">
      <p class="font-weight-bold text-justify mb-3" style="font-size: 17px; line-height: 30px" dir="rtl">
        على مدار سنوات الخبرة في سوق السيارات الأردني, تم تأسيس موقع جومزاد دوت كوم بناءً على حاجة طرف ثالث لتسهيل عملية بيع و شراء السيارات. نحن أول مزاد سيارات موثوق و منظم في الأردن, نقدم السيارات المستعملة للعملاء المحتملين ونوفر تجربة تسوق مريحة للسيارات مع خيارات متنوعة.
      </p>
      <div class="d-flex justify-content-center">
        <router-link :to="{name: 'contact'}" target="_blank" class="btn btn-accent rounded-0 min-w-100px">
          إتصل بنا
        </router-link>
      </div>
    </div>
  </div>
   <div class="row align-items-center mb-3">
     <div class="col-md-6">
       <img decoding="async"
            src="@/assets/jomazad/images/id-4-1024x680.png"
            class="w-100" alt=""
            srcset="@/assets/jomazad/images/id-4-1024x680.png 1024w, @/assets/jomazad/images/id-4-300x199.png 300w, @/assets/jomazad/images/id-4-768x510.png 768w, @/assets/jomazad/images/id-4-1536x1020.png 1536w, @/assets/jomazad/images/id-4-600x398.png 600w, @/assets/jomazad/images/id-4.png 1920w"
            sizes="(max-width: 1024px) 100vw, 1024px">
    </div>
    <div class="col-md-6">
      <p class="font-weight-bold text-justify mb-3" style="font-size: 17px; line-height: 30px">
تتمثل فكرتنا في تقديم خدمة للمشترين والبائعين حيث ينتهي الأمر بالطرفين بميزة كبيرة ، وهي أيضًا طريقة أكثر ملاءمة لبيع وشراء السيارات.
المشروع مزاد على الإنترنت ، فهو يوفر للبائعين فرصة تسويق سياراتهم بشكل أكثر فعالية والحصول على عوائد أعلى والاستفادة من عدد الجمهور الكبير. سيجد المشترون مصدرًا واحدًا للاختيار من بين عدد كبير من السيارات المستعملة نعتقد ان جو مزاد سيخلق فرصة لربط البائعين بالمشتريين بكل سهولة و يسر و دون متاعب من خلال ميزات الربط الالكتروني لعمليات البيع و الشراء.
      </p>
      <div class="d-flex justify-content-center">
        <router-link :to="{name: 'register'}" target="_blank" class="btn btn-accent rounded-0 min-w-100px">
          إنضم الآان
        </router-link>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <p class="font-weight-bold text-justify mb-3" style="font-size: 17px; line-height: 30px">
        تم تصميم عملية المزايدة الفعلية نفسها بطريقة تكون مثيرة وممتعة وتنافسية للمشترين. إنه وضع يربح فيه المشتري والبائع.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 px-2 mb-2 mb-lg-0">
      <div class="card border-0 bg-black-hover text-light rounded-0 h-100">
        <div class="card-header bg-transparent border-0">
          <h4 class="text-center">شراء المركبات</h4>
        </div>
        <div class="card-body bg-transparent border-0" style="font-size: 17px">
          نحن نقود تجربة جديدة لسوق السيارات الأردني بتكنولوجيا متطورة مدعومة بخبرة أحد عشر عاما" بمجال المزادات الإلكترونية.
          <br>
          نحن نعلم أن سوق السيارات بطريقته التقليدية لا تواكب متطلبات العصر الحديث و لذلك خدمات الشراء لدينا هي طريقة حديثة و صناعة راسخة.
        </div>
        <div class="card-footer bg-transparent border-0 d-flex justify-content-center">
          <router-link :to="{name: 'auctions.index'}" class="btn btn-success rounded-0" target="_blank">
            عرض جميع المركبات
          </router-link>
        </div>
      </div>
    </div>
     <div class="col-md-6 px-2 mb-2 mb-lg-0">
      <div class="card border-0 bg-black-hover text-light rounded-0 h-100">
        <div class="card-header bg-transparent border-0">
          <h4 class="text-center">بيع سيارتك</h4>
        </div>
        <div class="card-body bg-transparent border-0" style="font-size: 17px">
          أحصل على أفضل سعر لسيارتك يمكنك مراقبة حالة المزاد بكل شفافية و في أي وقت إستفد من قاعدة البيانات لدينا
          <br>
          نحن أفضل منصة للبيع الفعال.
        </div>
        <div class="card-footer bg-transparent border-0 d-flex justify-content-center">
          <router-link :to="{name: 'add.car'}" class="btn btn-success rounded-0" target="_blank">
            بيع سيارتك
          </router-link>
        </div>
      </div>
    </div>
</div>
</div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>