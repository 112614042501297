// "formData" AND "defaultUrl" MUST BE DEFINED FROM PARENT COMPONENTS
import arrays from "@/common/arrays";
import Storage from "@/common/Storage";
import types from "@/common/types";

export default {
  data() {
    return {
      filters: {},
      has_filters: false,
      links: [],
      list: [],
      total: "",
      url: "",
      perPage: 15,
      lastPage: "",
      storage: false,
      emptyListByValue: false,
    };
  },
  watch: {
    async formData() {
      if (this.useStorage) Storage.removeStartWith("pagination-");
      await this.setList();
    },
    async filters() {
      if (this.useStorage) Storage.removeStartWith("pagination-");
      await this.setList();
    },
  },
  computed: {
    emptyList() {
      return (
        (!this.list || this.list.length === 0) &&
        !this.$store.getters.shouldReload
      );
    },
    length() {
      return !!this.list ? this.list.length : 0;
    },
    paginationStorageName() {
      return !!this.storageName ? `pagination-${this.storageName}` : "";
    },
    useStorage() {
      return (
        !!this.paginationStorageName &&
        (!this.filters || Object.entries(this.filters).length === 0)
      );
    },
  },
  methods: {
    setFilters(filters) {
      this.has_filters = true;
      this.filters = filters;
      this.url = this.defaultUrl;
    },
    setPagination(data) {
      if (!!data) {
        this.list = types.objectToArray(data.data);
        this.links = types.objectToArray(data.links);

        this.lastPage = data.last_page;
        this.perPage = data.per_page;
        this.total = data.total;
        this.emptyListByValue = !this.list || this.list.length === 0;

        if (this.useStorage) {
          Storage.removeStartWith("pagination-");
          Storage.set(this.paginationStorageName, data, 3);
        }
      }
    },
    async setLink(link) {
      if (!!link) {
        if (this.useStorage) Storage.removeStartWith("pagination-");

        this.url = link.url;
        this.perPage = link.perPage;
        await this.setList();
      }
    },
    async setList() {
      const storedData = Storage.get(this.paginationStorageName);

      if (!!storedData && !!storedData.data) {
        return this.setPagination(storedData.data);
      }

      let url = !!this.url ? this.url : this.defaultUrl || "";

      if (!url) {
        return;
      }

      const response = await http.send(url, {
        ...this.formData,
        perPage: this.perPage,
        filters: this.filters,
        has_filters: this.has_filters,
        _method: "GET",
      });

      if (http.responseAccepted(response)) {
        await this.setPagination(response.data);
      }

      const position = this.$store.getters.position;
      if (!!position) {
        http.scrollToElement(`#${position}`);
        this.$store.dispatch("setPosition", "");
      }
    },
    updated(item) {
      if (!item || !this.list || this.list.length === 0) return;

      this.list = arrays.updateOrPush(this.list, item);

      if (this.useStorage) Storage.removeStartWith("pagination-");
    },
    async deleted(id, search_key = "id") {
      if (!id || !this.list || this.list.length === 0) return;

      this.list = arrays.removeById(this.list, id, search_key);

      if (
        this.list.length < 5 &&
        this.total > 5 &&
        this.total > this.list.length
      )
        await this.setList();
    },
  },
};
