import Storage from "@/common/Storage";
import router from "@/router";
import store from "@/store";
import types from "@/common/types";

const getters = {
  shouldReload(state) {
    return state.shouldReload;
  },
  shouldReloadMsg(state) {
    return state.shouldReloadMsg;
  },
  nationalities() {
    return ["أردني", "غير أردني"];
  },
  notes(state) {
    return !state.notes
      ? []
      : types.objectToArray(Storage.removeStorageKeys(state.notes));
  },
  cities(state) {
    return !state.cities
      ? []
      : types.objectToArray(Storage.removeStorageKeys(state.cities));
  },
  colors(state) {
    return !state.colors
      ? []
      : types.objectToArray(Storage.removeStorageKeys(state.colors));
  },
  areas(state) {
    return state.areas;
  },
  websockets() {
    return process.env.VUE_APP_PUSHER_HOST
  },
  publicPath() {
    return process.env.VUE_APP_SERVER_URL
  },
  userActivationCount(state) {
    return !isNaN(state.userActivationCount) &&
      parseInt(state.userActivationCount) > 0
      ? state.userActivationCount
      : 0;
  },
  insurances(state) {
    return state.insurances;
  },
  counters(state) {
    return state.counters;
  },
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  },
  adminWhatsapp(_, getters) {
    const prefix = getters.isMobile ? "whatsapp:/" : "https://web.whatsapp.com";
    return `${prefix}/send?phone=962786703009`;
  },
  adminFacebook() {
    return "https://facebook.com/JoMazad.Auction/";
  },
  partners(state) {
    return state.partners;
  },
  currentRoute() {
    return !(!router.currentRoute || !router.currentRoute.value)
      ? router.currentRoute.value.name
      : "";
  },
  intendedRoute(state) {
    let intended = state.intendedRoute;
    if (!intended) intended = store.getters.isAdmin ? "dashboard" : "/auctions";
    return intended;
  },
  dimensions(state) {
    return state.dimensions;
  },
  position(state) {
    return state.position;
  },
};

export default getters;
