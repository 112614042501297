<template>
  <div id="footer">
    <div class="container">
<!--      <div class="row">-->
<!--        <div class="col-sm-12">-->
<!--          <div class="mb-2">-->
<!--            <h1 class="wrap-text">-->
<!--              جو مزاد لا تضمن دقة أو توقيت أو أوصاف أو صور المزادات المعروضة على الموقع الإلكتروني، و بإستخدامك للموقع انت تخلي طرف جو مزاد من أي مسؤولية عن أي أخطاء-->
<!--              أو سهو في أوصاف أو صور المزادات المعروضة على الموقع.-->
<!--            </h1>-->
<!--            <h1 class="mt-4 wrap-text">-->
<!--              إستخدامك لهاذا الموقع الإلكتروني يكون على مسؤوليتك الخاصة إذ يمكن أن تحتوي المزادات في هذا الموقع على عدم الدقة أو أخطاء قد تؤثر على نوعية السيارات أو المزادات ، و لم يتم التحقق منها-->
<!--              بشكل مستقل أو منتجات مصادقة كلياً أو جزئياً من قبل جو مزاد-->
<!--            </h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row footer-links justify-content-end">
        <div class="col-sm-12">
          <p class="text-right">
            <strong>تابعنا: </strong>
            <a :href="facebook" title="صفحتنا على فيسبوك" target="_blank">
              <i class="fab fa-facebook fa-1-5x"></i>
            </a>
            <a :href="whatsapp" title="صفحتنا على واتساب" target="_blank" class="mx-1">
              <i class="fab fa-whatsapp fa-1-5x text-light-green"></i>
            </a>
            <router-link :to="{name: 'contact'}" title="البريد الإلكتروني" class="mx-1">
              <i class="fas fa-envelope fa-1-5x text-secondary"></i>
            </router-link>
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <p class="pm-0 text-center">
<!--            <router-link class="link danger" :to="{name: 'privacy'}">بيان الخصوصية</router-link>-->
<!--            <i class="dot"></i>-->
            <router-link class="link danger" :to="{name: 'conditions'}">الشروط والأحكام</router-link>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="br br-red my-2"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="copyrights text-center">
            <span>جميع الحقوق محفوظة</span>
            <sub>&copy;</sub>
            <span>{{ new Date().getFullYear() }}</span>
            <span>جو مزاد</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    whatsapp() {
      return this.$store.getters.adminWhatsapp
    },
    facebook() {
      return this.$store.getters.adminFacebook
    },
  },
}
</script>

<style scoped>
h1 {
  font-size: 1rem;
}

#footer {
  top: 100vh;
  border-top: 2px solid #f44336;
  width: 100%;
  padding: 20px 0;
  background-color: #f8f9fa
}

.br {
  display: block;
  width: 100%;
  height: 0.15rem;
  background-color: #f44336;
}

.copyrights {
  color: #f44336;
  font-weight: 700
}

.copyrights sub {
  font-size: 25px;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 0.25rem !important;
  background-color: #f44336;
  border-radius: 50%;
  display: inline-block;
}
</style>
